$mobile: 675px;

.tabsContainer {
    margin-top: 48px;
    border-bottom: 1px solid #cecdc6;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;
    /* stylelint-disable-next-line a11y/no-display-none */
    @media (max-width: $mobile) {
        display: none;
    }
}

.tabsContainerMobile {
    margin-top: 48px;
    border-bottom: 1px solid #cecdc6;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;

    @media (max-width: $mobile) {
        border-bottom: none;
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0;
    }
}

.innerContainer {
    display: flex;
    gap: 8px;

    @media (max-width: $mobile) {
        display: flex;
        gap: 8px;
        width: 100%;
        justify-content: space-between;
    }
}

.selection {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    background: #f5f4ed;
    letter-spacing: 0.01em;
    padding: 12px 24px 9px;
    border: none;
    border-radius: 4px 4px 0 0;
    border-bottom: 3px solid #f5f4ec;
    color: #31312f;
    font-size: 16px;
    &[aria-checked='true'] {
        color: #f5f4ec;
        background: #31312f;
        border-bottom: 3px #a7a6a0;
    }

    @media (max-width: $mobile) {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 88px;
        height: 36px;
        background: #f5f4ec;
        border: 1px solid #e4e3db;
        border-radius: 8px 8px 0.5px 0.5px;
        padding: 12px;
        font-size: 13px;
        &[aria-checked='true'] {
            color: white;
            background: black;
        }
    }
}
