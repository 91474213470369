$mobile: 720px;

.discographyError {
    text-align: center;
}

.loadingOverlay {
    height: 1000px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.noSearchResults {
    height: 350px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.discography {
    display: grid;
    grid-template-columns: 1fr 6fr;
    gap: 20px;

    @media (max-width: $mobile) {
        display: flex;
        flex-direction: column;
    }
}

.discographyGrid {
    display: block;
}

.sidebarContainer {
    min-width: 258px;

    @media (max-width: $mobile) {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: white;
        transition: transform 0.3s ease-out;
        transform: translateX(-100%);
        z-index: 1010;
        overflow: auto;
    }
}

.mobileSidebar {
    transform: translateX(0);
}

.releasesGrid {
    min-width: 100%;
}

.releases {
    display: grid;
    hyphens: auto;
    table-layout: fixed;
    width: 100%;

    thead {
        background: #f5f4ec;
        padding: 4px 16px;
        margin: 16px 0 8px;
        border-radius: 2px;
        display: block;

        @media (max-width: $mobile) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 28px;
            background: #f5f4ec;
            border-radius: 4px;
            padding: 4px 16px;
            font-style: normal;
            font-weight: 500;
            font-size: 1em;
            line-height: 143%;
        }
    }

    td {
        padding: 8px 0.25em;
        vertical-align: top;
    }

    h2 {
        margin: 0;
        padding: 4px 0;
    }

    tr {
        vertical-align: top;
        border-top: 1px solid #e5e5e5;
        &:first-of-type {
            border-top: 0;
        }
    }
}

.albumCovers {
    display: flex;
    flex-wrap: wrap;
    gap: 24px 20px;
    margin-top: 16px;

    img {
        border-radius: 2px;
        background: #f5f4ec;
    }

    @media (max-width: $mobile) {
        display: flex;
        justify-content: center;
    }
}

.filterBar {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.one {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    gap: 80px;

    @media (max-width: $mobile) {
        width: 100%;
        justify-content: space-between;
        gap: 0;
    }
}
