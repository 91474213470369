$mobile: 675px;

.sidebar {
    margin: 20px 25px 0 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (max-width: $mobile) {
        position: absolute;
        z-index: 1001;
        margin: 0;
        margin-top: 20px;
        width: 100%;
    }
}
/* stylelint-disable-next-line a11y/no-display-none */
.closeSidebar {
    display: none;

    @media (max-width: $mobile) {
        display: inline;
        position: relative;
        text-align: center;
        button {
            position: absolute;
            top: 0;
            left: 0;
            border: none;
            padding-left: 8px;
            background: none;
            font-size: 1em;
            font-weight: 500;
            color: #31312f;
            cursor: pointer;
            text-decoration: underline;
        }
        h2 {
            margin: 0;
        }
    }
}

.mobileUiLoadingState {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
}

.subFacetTotalCounts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    gap: 8px;
    background: #f5f4ec;
    border-radius: 24px;
    p {
        color: #31312f;
        font-weight: normal;
        font-size: 1em;
        line-height: 14px;
        letter-spacing: 0.01em;
        margin-block-start: 0;
        margin-block-end: 0;
    }

    @media (max-width: $mobile) {
        background: #f5f4ec;
        border-radius: 96px;
        height: 18px;
        svg {
            width: 16px;
        }

        .mobileFocusUI {
            background: #31312f;
            color: white;
        }
    }
}

.facetsContainer {
    @media (max-width: $mobile) {
        padding: 16px;
        button {
            width: 100%;
        }
    }
}

.creditsFacetsContainer {
    margin-left: 20px;

    @media (max-width: $mobile) {
        position: relative;
        padding-left: 16px;
        padding-right: 16px;
        button {
            width: 100%;
            svg {
                width: 16px;
            }
        }
    }
}

.subFacetTotalCountsSelected {
    background: #31312f;
    p {
        color: #f5f4ec;
    }
}

.superFacets {
    display: grid;
    grid-template-columns: 0.1fr 5fr 0.1fr;
    align-content: center;
    align-items: center;
    padding: 8px 12px;
    width: 232px;
    height: 44px;
    border: none;
    background-color: transparent;
    border-radius: 6px;
    text-align: left;
    cursor: pointer;
    /* Ideally we want to remove &:focus here, a user may be focusing on the superFacet just to close it
     - will talk to Josh once this is merged in and see how we can appease a11y standards without
     causing confusion for regular users */
    &:hover,
    &:focus {
        background: #f5f4ec;
    }

    svg {
        width: 16px;
        color: #6c6b68;
    }

    @media (max-width: $mobile) {
        grid-template-columns: 1fr 5fr 1fr;
        grid-auto-columns: minmax(0, auto);
        grid-auto-flow: column;
        svg {
            width: 16px;
        }

        .mobileFocusUI {
            background: #31312f;
            color: white;
        }
    }

    p:first-of-type {
        font-weight: bold;
        font-size: 1.3em;
        line-height: 20px;
        color: #31312f;
    }
}

.expanded {
    background: #f5f4ec;
    svg {
        color: #31312f;
    }

    @media (max-width: $mobile) {
        border-radius: 8px;
    }
}

.superFacet {
    padding: 16px 8px;
}

.superFacetCount {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    height: 28px;
    background: #f5f4ec;
    mix-blend-mode: multiply;
    border-radius: 24px;
    box-sizing: border-box;
    text-align: center;
    color: #31312f;
    font-size: 1em;
    line-height: 18px;
    letter-spacing: 0.01em;
}

.superFacetCountExpanded {
    background: #31312f;
    color: #f5f4ec;
}

.mobileSelectedTick svg {
    margin-left: 8px;
    margin-right: 12px;
}

.facets {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 232px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    text-align: left;
    background-color: transparent;
    &:hover,
    &:focus-within {
        background: #f5f4ec;
    }
    svg {
        width: 16px;
        color: #6c6b68;
        margin-right: -10px;
    }
    .iconDown {
        width: 16px;
    }

    @media (max-width: $mobile) {
        position: relative;
        z-index: 2000;
        grid-template-columns: 1fr 5fr 1fr;
        grid-auto-columns: minmax(0, auto);
        grid-auto-flow: column;
        width: 100%;
        padding: 16px 4px 16px 16px;
        svg {
            width: 16px;
            margin-right: 0;
        }
    }
}

.creditFacets {
    width: 204px;
}

.facet {
    width: 75%;
    font-weight: normal;
    font-size: 1.2em;
    line-height: 1.5rem;
    padding: 16px 8px 16px 32px;
    text-align: left;
    color: #31312f;
    margin-block-start: 0;
    margin-block-end: 0;

    @media (max-width: $mobile) {
        padding: 16px 4px 16px 16px;
        margin: 0;
    }
}

.subFacets {
    cursor: pointer;
    padding-left: 48px;
    width: 75%;
    font-weight: normal;
    font-size: 1.1em;
    line-height: 20px;
    padding-bottom: 10px;
    color: #31312f;

    @media (max-width: $mobile) {
        padding-right: 4px;
        padding-left: 48px;
        margin: 0;
    }
}

.facetSelected {
    background: #f5f4ec;

    @media (max-width: $mobile) {
        position: relative;
        z-index: 2000;
    }
}

.mobileCreditFacetExpander {
    appearance: none;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    width: 28px !important;
    z-index: 2001;
}
