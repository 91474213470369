/* disabled to allow no-vendor-prefix line-clamp without the prefix, it's not universal yet. */
/* stylelint-disable */
.profileContainer p {
    margin: 0;
}

.profileText {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.hideContent {
    -webkit-line-clamp: unset;
}
