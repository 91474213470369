.nameVarContainer {
    display: flex;
    align-items: flex-start;
}

.nameVarContainer a {
    margin: 0 8px;
}

.nameVarContainer span {
    margin-right: 8px;
}

.nameVarContainer p {
    background: #2653d9;
    color: white;
    padding: 2px 15px;
    border-radius: 5px;
    margin: 0 8px;
}

.nameVarContainer a:first-child {
    margin: 0 8px 0 0;
}

.active {
    background: #2653d9;
    color: white;
    padding: 2px 16px;
    border-radius: 4px;
    margin: 0 8px;
}

a:visited.active {
    color: white;
}

.variations {
    margin-top: 10px;
}
