$mobile: 600px;

.desktopActionsContainer {
    @media (max-width: $mobile) {
        display: none;
    }
}

.spacer {
    width: 100%;
    flex-shrink: 1;
}

.header {
    white-space: nowrap;
}
/* stylelint-disable-next-line a11y/no-display-none */
.mobileActionsButton {
    display: none;

    @media (max-width: $mobile) {
        display: flex;
        justify-content: flex-end;
        margin-bottom: -35px;
    }
}

.ellipsisContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 20px;
    height: 20px;
    background: #f5f4ec;
    border: 1px solid #e4e3db;
    border-radius: 24px;
    cursor: pointer;
    svg {
        width: 16px;
        color: black;
    }
}

.mobileActionsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    right: 40px;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.98);
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.mobileActionsContainer span {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;
    width: 150px;
    button {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
    }

    svg {
        width: 16px;
        color: black;
    }
    &:hover,
    &:focus-within {
        background: #f2f2f2;
    }
}

.mobileActionsContainer span:first-child {
    border-bottom: 1px solid black;
}

.copyButton {
    display: flex;
    align-items: center;
    gap: 8px;
}
