.videos {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 155px;
    overflow: auto;
    position: relative;
    overflow-x: hidden;
}

.video {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: row;
    background: none;
    border: none;

    margin: 0;
    padding: 0;
    margin-bottom: 0.5em;
    font-family: inherit;
    font-size: 13px;
    width: 100%;
    cursor: pointer;
    text-align: inherit;
}

.player {
    background: black;
    margin-bottom: 0.5em;
    line-height: 0;
    height: 240px;
}

.thumbnail {
    display: flex;
    width: 120px;
    height: 90px;
    opacity: 0.8;
    flex-shrink: 0;
    flex-grow: 0;

    .video.active &,
    .video:hover &,
    .video:focus {
        opacity: 1;
    }
}

.title {
    color: #2653d9;
    font-weight: bold;
    padding-left: 0.5em;

    .video:hover &,
    .video:focus {
        text-decoration: underline;
        color: #07b;
    }
}

.duration {
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
    background: black;
    border-radius: 1px;
    padding: 1px 2px;
    font-size: 12px;
    z-index: 1;
}

.media {
    position: absolute;
    height: 1em;
    top: 45px;
    left: 60px;
    color: white;
    margin-top: -0.5em;
    margin-left: -0.5em;
    z-index: 2;
    opacity: 0;
}

.play {
    composes: media;

    .video:hover &,
    .video:focus {
        opacity: 1;
    }

    .video.active:hover &,
    .video.active:focus {
        opacity: 0;
    }
}

.pause {
    composes: media;

    .video.active & {
        opacity: 1;
    }
}

.edit {
    font-size: 0.85em;
    margin-left: 1em;
    margin-top: 0.2em;
}

h2.head {
    width: auto;
}

.icon {
    color: #2653d9;
    height: 1em;
    margin-left: 0.5em;
    width: 1em;
}
