.player {
    background: black;
    position: relative;
    box-sizing: border-box;
    padding: 0;
}

.placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    background: black;

    border: none;
    padding: 0;

    &:disabled {
        cursor: default;
    }

    img {
        margin: auto;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
}

.youtube {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 64px;
    height: 64px;

    .background {
        fill: #222;
        fill-opacity: 0.8;
    }

    .foreground {
        fill: white;
    }

    .placeholder:focus &,
    .placeholder:hover & {
        .background {
            fill: red;
            fill-opacity: 1;
        }
    }

    .placeholder:disabled & {
        .background {
            fill: #222;
            fill-opacity: 1;
        }

        .foreground {
            fill: #aaa;
        }
    }
}
