.artistHeader h2 {
    min-width: 75px;
}

.artistHeader th {
    padding-right: 0;
}

table {
    border-collapse: collapse;
    line-height: 18px;
    width: 100%;
    table-layout: auto;
}
