$mobile: 600px;

.contributorActions {
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;
    button {
        border: none;
        background: transparent;
    }
    svg {
        width: 20px;
    }

    @media (max-width: $mobile) {
        display: none;
    }
}

.linkContainer {
    z-index: 102;
    position: absolute;
    margin-top: 25px;
    margin-right: 10px;
    box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08);
    border-radius: 4px;
    span {
        cursor: pointer;
        padding: 12px 16px;
        display: flex;
        justify-content: left;
        align-items: center;
        border: 1px solid #e4e3db;
        background: white;
        &:hover,
        &:focus {
            background: #ccc;
        }
        a {
            width: 100%;
            color: black;
            line-height: 18px;
            font-size: 14px;
            &:hover,
            &:focus {
                text-decoration: none;
                color: inherit;
            }
        }
    }
    span:first-child {
        border-bottom: none;
    }

    span:last-child {
        border-top: none;
    }
}
