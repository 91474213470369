.more {
    .thumbnail,
    p button {
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
    }

    p {
        font-size: 0.75rem;
        margin-top: 4px;
        text-align: center;

        @media screen and (max-width: 550px) {
            display: none;
        }

        button {
            margin: 0;
            padding: 0;
            color: #2653d9;
        }
    }
}

.add {
    font-size: 0.75rem;
    margin-bottom: 0;
    margin-top: 8px;
    text-align: center;
}

.moreImages {
    font-size: 0.75rem;
    margin-top: 8px;
    text-align: center;

    @media screen and (max-width: 550px) {
        display: none;
    }
}

.moreImages:hover,
.moreImages:focus {
    text-decoration: underline;
}
