$mobile: 600px;

.artistTabsContainer {
    display: flex;
    margin-bottom: 3em;
    header {
        border-bottom: none;
        padding: 0;
    }

    section {
        width: 60%;
        margin-right: 0;
    }

    @media (max-width: $mobile) {
        section {
            width: 100%;
        }
    }
}
